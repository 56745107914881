.home-team,
.away-team {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  color: #ffffff;

  div {
    padding: 10px;
  }
}
.team-shield {
  width: 25px;
}
.fixture-line {
  width: 100%;
  background-color: #895389;
  cursor: pointer;
  &:hover {
    background-color: #000000;
    color: #fff;
  }

  div {
    margin: auto;
  }
}
.team-score {
  padding: 5px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10%;
  font-size: 24px;
  font-weight: bold;
  margin: 10px !important;
}
.team-name {
  min-width: 100px;
  text-align: center;
}
