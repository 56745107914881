.player-stats-icon {
  flex: 1;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-left: -8px;
  color: #fff;
  align-self: center;
  svg {
    height: 100%;
  }
}
.player-stats-icon-container {
  display: flex !important;
  padding: 12px;
}
.player-stats-value {
  flex: 2;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  align-self: center;
}
.player-badge {
  margin: 12px;
  text-align: center;
  border: #895389;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  vertical-align: top;
}

.player-stats-header {
  background-color: #895389;
  color: #fff;
  width: 100%;
  p {
    font-size: 12px !important;
  }
}
.player-stats-name-container {
  background: rgb(137, 83, 128);
  background: linear-gradient(
    90deg,
    rgba(137, 83, 128, 1) 0%,
    rgba(137, 83, 128, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
