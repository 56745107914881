.home-container {
  background: linear-gradient(165deg, #161516, transparent);
}
.pitch {
  width: 100%;
  height: 100%;
  background-image: url("http://localhost:3000/pitch.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.players-table,
.cards-table {
  margin: 24px auto;
  width: 100% !important;
  height: 80vh;
  th {
    background: #ffffff;
    color: rgb(137, 83, 128);
    text-align: left;
  }
  td,
  th {
    font-weight: bold;
  }
  tr:nth-child(even),
  tr:nth-child(odd) {
    background-color: #ffffff !important ;
    td {
      color: #895389 !important;
    }
  }
  tr:hover {
    background-color: #000;
    td {
      color: #fff;
    }
  }
}
.pitch-section {
  display: flex !important;
}
.substitutes-section div {
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.substitutes-section {
  flex: 1;
  background: #0eba00;
}
.substitutes-section-header {
  background-color: #895389;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  padding: 12px;
  text-align: left;
}
.team-status {
  text-align: center;
  background-color: #895389;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  div {
    margin: 0 12px;
    font-weight: bold;
    color: #ffffff;
    display: inline-table;
    align-items: left;
    background-color: #ffffff;
    color: #895389;
    padding: 2px;
    width: 120px;
    div:nth-child(even) {
      margin-left: 12px;
      background-color: #895389;
      color: #ffffff;
      padding: 4px;
    }
  }
}
.cover {
  display: flex;
  justify-content: center;
  height: 300px;
  width: 100%;
  background: url("../../../public/cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 57%;
}

.botola-pro-container {
  background: #ffffff;
  border-radius: 50%;
  height: 250px;
  width: 250px;
  padding: 10px;
  margin: auto;
  border: 5px solid #895389;
}
.botola-pro {
  height: 250px;
  width: 250px;
  background: url("../../../public/botola_pro.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.control-area {
  background-color: #ffffff;
  color: #895389;
}
.cards-module-view div {
  display: inline-block;
}
.cards-module-view {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  max-height: 80vh;
  height: 80vh;
  overflow-y: scroll;
  .player-badge {
    cursor: pointer;
    .player-stats-value,
    .player-stats-icon {
      color: #895389;
    }
  }
}
