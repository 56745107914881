.time-counter {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;

  span:nth-child(1) {
    height: 10px;

    span {
      background: #895389;
    }
  }
}
