.match-container {
  background: url("http://localhost:3000/cover_match.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  min-height: 100vh;
  .team-budget {
    background-color: #ffffff;
    color: #895389;
    padding: 4px;
    border-radius: 4px;
  }

  .pitch {
    width: 100%;
    height: 100%;
    background-image: url("http://localhost:3000/pitch.svg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .players-table,
  .cards-table {
    margin: 24px auto;
    width: 80% !important;
    height: 70vh;
    th {
      background: rgb(137, 83, 128);
      color: #ffffff;
    }
    td,
    th {
      font-weight: bold;
    }
    tr:nth-child(even) {
      background-color: #895389;
      td {
        color: white;
      }
    }
    tr:hover {
      background-color: #000;
      td {
        color: #fff;
      }
    }
  }
  .pitch-section {
    display: flex !important;
  }
  .substitutes-section div {
    flex-direction: column;
    justify-content: center;
    max-width: 116px;
  }
  .substitutes-section {
    flex: 1;
    background: #0eba00;
  }
  .substitutes-section-header {
    background-color: #895389;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding: 12px;
    text-align: left;
  }

  .team-name-section-header {
    background-color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    color: #895389;
    padding: 12px;
    text-align: left;
    &__away {
      background-color: #895389;
      color: #ffffff;
    }
  }

  .team-status {
    text-align: center;
    background-color: #895389;
    display: flex;
    flex-direction: row;
    padding: 3px;
    div {
      font-weight: bold;
      display: inline-table;
      align-items: center;
    }
  }
  .formation-select {
    position: fixed;
    bottom: 0px;
    display: flex;
    .formation-title {
      background-color: #fff;
      font-size: 15px;
      font-weight: bold;
      color: #895389;
      padding: 20px;
    }
    .formation-strategy {
      cursor: pointer;
      background: rgb(137, 83, 128);

      font-size: 15px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      padding: 20px;
      &:hover {
        color: #895389;
        background: #ffffff;
      }
      &__hybrid {
        background: #000000;
        color: #fff;
        &:hover {
          color: #fff;
          background: #000;
        }
      }
    }
    .formation-select {
      display: flex;
      flex-direction: column;
      button {
        padding: 4px;
      }
    }
  }
  .match-status {
    text-align: center;
    background: rgb(137, 83, 128);
    background: linear-gradient(
      180deg,
      rgba(137, 83, 128, 1) 0%,
      rgba(137, 83, 128, 1) 35%,
      rgba(255, 255, 255, 1) 100%
    );
    display: flex;
    flex-direction: row;
    padding: 15px;
    margin-bottom: 12px;
    .match-score {
      margin-left: 12px;
      background-color: #ffffff;
      color: #895389;
      padding: 12px;
      border-radius: 6px;
    }
    .match-time {
      margin-left: 12px;
      background-color: #000000;
      color: #ffffff;
      padding: 6px;
      border-radius: 6px;
      margin: auto;
      flex-direction: column;
      text-align: center;
      .match-time-text {
        margin: auto auto 12px auto;
      }
    }
    &:nth-child(1) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      div:nth-child(2) {
      }
    }
    &:nth-child(3) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    div {
      margin-right: 12px;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      display: inline-flex;
      align-items: center;
    }
  }
  .home-team,
  .away-team {
    // width: 150px;
    // text-align: center;
  }
  .match-status-container {
    box-shadow: none !important;
  }
}
.mouse-over-popover,
.mouse-over-popover-result {
  padding: 12px !important;
  border: solid 4px #895389 !important;
  .mouse-over-popover-title {
    background: #895389;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .mouse-over-popover-description {
    background: lightgray;
    padding: 5px;
  }
  .mouse-over-popover-attribute-container {
    display: flex;
    flex-direction: row;
    .mouse-over-popover-attribute {
      font-size: 16px;
    }
  }
}
.mouse-over-popover-result {
  padding: 0px !important;
  .mouse-over-popover-title {
    margin: 0;
    color: #895389;
    background: white;
  }
}

.view-toggle-selection {
  background-color: #000;
  button {
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
  }
}
.stats-view {
  width: 100%;
  background-color: rgba(227, 227, 227, 0.4);
  div {
    display: inline-table;
  }
  .player-stats-icon-container {
    background: black;
    margin: auto;
  }
}

.timeline-view {
  width: 100%;
  background-color: rgba(227, 227, 227, 0.4);
  margin-top: 12px;
  padding: 5px;
  &__events {
    background-color: black;
  }
  .timeline-titles div {
    margin: auto;
  }
  .player-stats-icon-container {
    background: black;
    margin: auto;
  }
  .alert {
    background: #ffffff !important;
    color: #895389 !important;
    font-size: 18px !important;
    font-weight: bold !important;
    width: 80%;
    padding: 0 !important;
  }
  .alert__away {
    flex-direction: row-reverse;
    background: #895389 !important;
    font-size: 18px !important;
    font-weight: bold !important;
    width: 80%;
    align-self: end;
    padding: 0 !important;
  }

  .event-alert {
    background: lightgray !important;
    color: #895389 !important;
    font-size: 18px !important;
    font-weight: bold !important;
    width: 80%;
    padding: 0 !important;
  }
  .event-alert__away {
    flex-direction: row-reverse;
    background: lightgray !important;
    color: #895389;
    font-size: 18px !important;
    font-weight: bold !important;
    width: 80%;
    align-self: end;
    padding: 0 !important;
  }
  .timeline-icon {
    vertical-align: middle;
    margin: 12px;
  }
  .MuiAlert-message {
    padding: 0 !important;
  }
}

.captain-view {
  display: flex;
  flex: 1;
  background-color: rgba(227, 227, 227, 0.4);
  padding: 12px;
  .capitain-voice {
    margin: 12px;
  }
  .captain-messages {
    flex-direction: column;
    width: 100%;
  }
}
