.admin-section-header {
  width: 100%;
  background-color: #895389;
  color: #ffffff;
  margin-bottom: 15px;
  padding: 15px;
}
.admin-section {
  width: 100%;
}
.admin-section-wrapper {
  margin: 10px;
}
.fixture-results-action {
  background-color: #895389 !important;
  padding: 15px !important;
  margin-top: 15px !important;
  margin-bottom: 100px !important;
  color: white !important;
  font-weight: bold !important;
}
