.player {
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  min-height: 200px;
  max-width: 100px;
  &:hover {
    background-color: #89538966;
    border-radius: 5px;
  }
}
.player-name-placeholder {
  border-radius: 5px !important;
}
.player-name,
.player-name-placeholder {
  padding: 4px;
  background: #895389;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.player-club {
  padding: 4px;
  background: #ffffff;
  color: #895389;
  font-weight: bold;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  span {
    vertical-align: super;
  }
}
.player-glimpse {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.statistics-section div,
.goalkeeper-section div,
.discipline-section div {
  display: inline-block;
}
.ratio-section {
  width: 100%;
}
.player-selected {
  background-color: #89538966;
  border-radius: 5px;
}
.statistics-section,
.goalkeeper-section,
.discipline-section {
  text-align: center;
}
.actions-area {
  text-align: center;
  margin-top: 12px;
  button {
    width: 80%;
    padding: 5px;
    background: #895389;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 12px;
    &:hover {
      background-color: #ffffff;
      color: #895389;
    }
  }
}
.actions-area-save {
  text-align: center;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  button {
    width: 80%;
    padding: 5px;
    background: #895389;
    color: #ffffff;
    font-weight: bold;
    &:hover {
      background-color: #ffffff;
      color: #895389;
    }
  }
}

.player-name,
.player-club {
  width: 100%;
}
.captain-action {
  margin-top: 12px !important;
}
